import * as React from "react";
import { useEffect } from "react";
import { Link } from "gatsby";
import "./layout.css";
import { motion, AnimatePresence, useCycle } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";

export const Container = ({ isVisible, children, location }) => {
  useEffect(() => {
    setTimeout(function () {
      document.body.classList.add("loaded");
    }, 600);

    window.onscroll = function (e) {
      var scrollY = window.pageYOffset || document.documentElement.scrollTop;
      var header = document.getElementById("header");
      var hr = header.querySelector("hr");
      var height = -header.clientHeight;

      if (scrollY >= 100) {
        header.style.transition =
          "transform 0.2s cubic-bezier(.645, .045, .355, 1)";
        hr.style.visibility = "visible";
      }

      scrollY <= Math.max(this.lastScroll, 10) ||
      window.innerWidth <= 350 ||
      this.loaded === undefined
        ? (header.style.transform = "translateY(0px)")
        : (header.style.transform = "translateY(" + height + "px)");

      this.lastScroll = scrollY;
      this.loaded = true;

      if (scrollY <= 10) {
        header.style.transition =
          "transform .2s cubic-bezier(.645, .045, .355, 1)";
        hr.style.visibility = "hidden";
      }
    };
  });

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key={location.pathname}
          className="page-container"
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: {
              delay: 0.4,
              ease: [0.645, 0.045, 0.355, 1],
              duration: 1,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              ease: [0.645, 0.045, 0.355, 1],
              duration: 0.4,
            },
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Seo = () => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsSite {
        faviconMetaTags {
          tags
        }
      }
    }
  `)

  return <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
}

const Layout = (props) => {
  const [isVisible] = useCycle(true, false);
  return (
    <Container isVisible={isVisible} {...props}>
      <Seo />

      <div className="page">
        <nav id="header">
          <div>
            <Link to="/">Linda Schneider</Link>
            <Link to="/about/">About</Link>
          </div>
          <hr />
        </nav>
        <main>
          <motion.div
            initial="offset"
            animate="final"
            variants={{
              offset: {
                y: 100,
              },
              final: {
                y: 0,
                transition: {
                  duration: 1,
                  ease: [0.645, 0.045, 0.355, 1],
                },
              },
            }}
          >
            {props.children}
          </motion.div>
        </main>
      </div>
    </Container>
  );
};

export default Layout;
