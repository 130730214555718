import "./src/styles/global.css"
import "./src/components/preloader.css"

import PageLayout from "./src/components/wrap-element"
export const wrapPageElement = PageLayout

const transitionDelay = 400

export const shouldUpdateScroll = () => false

export const onRouteUpdate = () => {
  window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
}